import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filters"
export default class extends Controller {
  static targets = ["filter", "listing", "link"];

  connect() {
    // Listen for popstate events (back/forward button presses)
    this.EMPTY_FILTERS = {condition: [], ram: [], ssd: [], cpu_series: [], resolution: [], size: [], year: [], model: []};
    window.addEventListener("popstate", this.handlePopState.bind(this));

    if (!this.shouldShowDefaultFilters()) {
      this.updateFromUrl();
    }

    this.setupLinks();
  }

  disconnect() {
    // Clean up the event listener when the controller is disconnected
    window.removeEventListener("popstate", this.handlePopState.bind(this));
  }

  updateTable(filterValues) {
    this.listingTargets.forEach(row => {
      const condition = row.querySelector('td[data-col="condition"]').textContent;
      const ram = row.querySelector('td[data-col="ram"]').textContent;
      const ssd = row.querySelector('td[data-col="ssd"]').textContent;
      const resolution = row.querySelector('td[data-col="resolution"]').textContent;
      const size = row.querySelector('td[data-col="size"]').textContent;
      const year = row.querySelector('td[data-col="year"]').textContent;
      const model = row.querySelector('td[data-col="model"]').dataset.model;
      const cpu_series = row.querySelector('td[data-col="cpu"]').textContent.split("-")[0];
      
      const show = filterValues.condition.includes(condition) &&
        filterValues.ram.includes(ram) &&
        filterValues.ssd.includes(ssd) &&
        (filterValues.cpu_series.includes(cpu_series) || (filterValues.cpu_series.includes('None') && cpu_series == '')) &&
        (filterValues.resolution.includes(resolution) || (filterValues.resolution.includes('None') && resolution == '')) &&
        filterValues.size.includes(size) &&
        filterValues.year.includes(year) &&
        filterValues.model.includes(model);

      if (show) {
        row.classList.remove('hidden');
        row.classList.add('flex', 'flex-col', 'md:table-row');
      } else {
        row.classList.add('hidden');
        row.classList.remove('flex',  'flex-col', 'md:table-row');
      }
    });
  }

  updateCheckboxes(filterValues) {
    this.filterTargets.forEach(checkbox => {
      if (filterValues[checkbox.dataset.param].includes(checkbox.name)) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    });
  }

  updateFromUrl() {
    var filterValues = JSON.parse(JSON.stringify(this.EMPTY_FILTERS));
    if (this.shouldShowDefaultFilters()) {
      filterValues = DEFAULT_SELECTIONS; // From Rails view
    } else {
      const params = new URLSearchParams(window.location.search);
      for (const [param, value] of params) {
        filterValues[param] = value.split(",");
      }
    }

    this.updateCheckboxes(filterValues);
    this.updateTable(filterValues);
  }

  shouldShowDefaultFilters() {
    const paramKeys = new Set(new URLSearchParams(window.location.search).keys());
    const filterKeys = Object.keys(this.EMPTY_FILTERS);
    const intersection = filterKeys.filter(key => paramKeys.has(key));

    return intersection.length == 0;
  }

  handleFilterChange() {
    const url = new URL(window.location);

    const filterValues = this.filterTargets.reduce((acc, checkbox) => {
      if (checkbox.checked) {
        acc[checkbox.dataset.param].push(checkbox.name);
      }
      return acc;
    }, JSON.parse(JSON.stringify(this.EMPTY_FILTERS)));
    const params = Object.entries(filterValues).reduce((acc, [param, values]) => {
      acc[param] = values.join(",");
      return acc;
    }, JSON.parse(JSON.stringify(this.EMPTY_FILTERS)));

    this.updateTable(filterValues);

    // Set URL search params
    url.search = new URLSearchParams(params).toString();
    history.pushState({}, '', url.toString());
  }

  handlePopState() {
    this.updateFromUrl();
  }

  setupLinks() {
    const currentUrls = [];

    this.linkTargets.forEach(link => {
      const url = link.href;

      if (localStorage.getItem(url)) {
        currentUrls.push(url);
        link.classList.remove('text-ieblue');
        link.classList.add('text-iepurple');
      }

      link.addEventListener('click', () => {
        localStorage.setItem(url, true);
        link.classList.remove('text-ieblue');
        link.classList.add('text-iepurple');
      });
    });

    localStorage.clear();
    currentUrls.forEach(url => {
      localStorage.setItem(url, true);
    });
  }
}
